import { Form, Input, Modal } from "antd";
import React from "react";

export default function ExperienciasForm({ visible, alCerrar, alAceptar }){
    const [form] = Form.useForm();
    const bgColor = "rgba(7, 21, 41, 0.7)";

    return (<Modal 
      title={<p style={{color: "white"}}>Nueva Experiencia 360</p>}
      modalRender={(node) => (
        <div style={{ color: 'white' }}>{node}</div>
      )}
      styles={{
        label: {
          color: "white"
        },
      mask: {
        backgroundColor: "rgba(0, 0, 0, 0.3)" 
      },
      content: {
        borderRadius: "8px",
        backgroundColor: bgColor
      }
      ,
      // body: {
      //   backgroundColor: "rgba(117, 103, 165, 0.1)"
      // },
      // footer: {
      //   backgroundColor: bgColor,
      //   borderTop: "1px solid #e8e8e8"
      // },
      header: {
        backgroundColor: "rgba(7, 21, 41, 0.2)", 
        borderBottom: "1px solid #e8e8e8",        
      }
      }}        
      open={visible} 
      onOk={() => {
      form
      .validateFields()
      .then((values) => {            
      alAceptar(values);
      form.resetFields();
      //onCreate(values);
      })
      .catch((info) => {
      console.log('No puede Continuar:', info);
      });
      }} onCancel={alCerrar}>
        <Form form={form}                  
          layout="vertical"
          name="experienciaForm"
          initialValues={{
          modifier: 'public',
         }}>
        <Form.Item  name="titulo"            
            label={<p style={{color: "white"}}>Titulo</p>}
            rules={[
            {
              required: true,
              message: 'Ingrese el titulo de la Experiencia',
            },]}>
               <Input />                        
        </Form.Item>
        </Form>
      </Modal>)
}