import React, { useState } from "react";
import { Button, Flex, Form, Input, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

export default function EscenaForm({ visible, alCerrar, alAceptar, modo }) {
  const [form] = Form.useForm();
  const [imagen, setImagen] = useState(null);

  const handleBeforeUpload = (file) => {
    if (modo) {
      // Usar FileReader si modo es true
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target.result);
        setImagen(e.target.result); // Guardar la imagen como DataURL
      };
      reader.readAsDataURL(file);
    } else {
      // Guardar el archivo directamente si modo es false
      setImagen(file); // Guardar el archivo en el estado
    }

    return false; // Prevenir el comportamiento por defecto de subir el archivo
  };

  return (
    <Modal
      open={visible}
      title="Crear nueva Escena"
      okText="Crear"
      cancelText="Cancelar"
      onCancel={() => {
        form.resetFields();
        alCerrar();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {            
            values.imageContent = imagen;
            alAceptar(values);
            form.resetFields();
            setImagen(null);
            //onCreate(values);
          })
          .catch((info) => {
            console.log("No puede Continuar:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="escenaForm"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="titulo"
          label="Titulo"
          rules={[
            {
              required: true,
              message: "Ingrese el titulo de la Escena",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="descripcion" label="Descripcion">
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="imagen"
          label="Imagen 360°"
          rules={[
            {
              required: true,
              message: "Seleccione una imagen 360",
            },
          ]}
        >
          <Flex gap="middle" horizonal justify="space-around" style={{ marginTop: "10px" }}>
            <Upload
              accept=".png, .jpg"
              maxCount={1}
              beforeUpload={handleBeforeUpload}
            >
              <Button icon={<UploadOutlined/>} type="primary">Click para subir Imagen</Button>
            </Upload>
            <Upload
              accept=".png, .jpg, .jpeg"
              maxCount={3}
              multiple
              action={(file) => {                
                console.log("File selected:", file);
                // Modal para cada archivo seleccionado
                Modal.confirm({
                  title: `Detalles para ${file.name}`,
                  content: (
                  <Form layout="vertical">
                    <Form.Item 
                    label="Título" 
                    name="titulo"
                    rules={[{ required: true, message: "El título es obligatorio" }]}
                    >
                    <Input id={`titulo-${file.uid}`} required />
                    </Form.Item>
                    <Form.Item 
                    label="Descripción" 
                    name="descripcion"
                    rules={[{ required: true, message: "La descripción es obligatoria" }]}
                    >
                    <Input id={`descripcion-${file.uid}`} required />
                    </Form.Item>
                  </Form>
                  ),
                  onOk() {
                    const titulo = document.getElementById(`titulo-${file.uid}`).value;
                    const descripcion = document.getElementById(`descripcion-${file.uid}`).value;
                    let bandera = true;
                    
                    // Validate inputs
                    if (!titulo) {
                      // Modal.error({
                      // title: 'Error',
                      // content: 'El título es obligatorio',
                      // });
                      bandera = false;
                      // Resolve the promise after 3 seconds to re-enable the OK button
                      //return new Promise((resolve) => setTimeout(resolve, 3000));
                    }

                    if (!descripcion) {
                      // Modal.error({
                      // title: 'Error',
                      // content: 'La descripción es obligatoria',
                      // });
                      bandera = false;
                      // Resolve the promise after 3 seconds to re-enable the OK button
                      //return new Promise((resolve) => setTimeout(resolve, 3000));
                    }
                    
                    if(bandera){
                    const fileObj = {
                      titulo: titulo,
                      descripcion: descripcion,
                      imageContent: file,
                      fromArray: true,
                    };
                    
                   // console.log("Imagen procesada:", fileObj);
                    alAceptar(fileObj);
                    // Validate the inputs manually                    
                  }else{
                    
                    const errorModal = Modal.error({
                      title: 'Error',
                      content: 'Los datos son Obligatorios',
                    });

                    // Close the error modal after 3 seconds
                    setTimeout(() => {
                      errorModal.destroy();
                    }, 3000);
                    return false;
                  }
                }
                });
                return false;
              }
              }
            onChange={(info) => {              
              // if (info.fileList.length === 0) {
              //   console.log("Upload component empty/closed");                
              // }
              
              const allDone = info.fileList.every(file => 
                file.status === 'done' || file.status === 'error'
              );
              
              if (allDone && info.fileList.length > 0) {
                
                // info.fileList.forEach(file => {
                //   file.status = 'removed';
                // });

                // Close the main modal
                form.resetFields();
                setImagen(null);
                alCerrar();

                
                // const newFileList = [];
                // if (info.onChange) {
                //   info.onChange({
                //     ...info,
                //     fileList: newFileList
                //   });
                // }
                // Add your post-processing logic here
              }else{
                //console.log("Some files are still pending");
              }
            }}
            >
              <Button icon={<UploadOutlined/>} type="primary">Subir Varias Imagen</Button>
            </Upload>
          </Flex>

          {/* <Upload accept=".png, .jpg" 
                    maxCount={1}
                    beforeUpload={file => {
                      
                      const reader = new FileReader();
              
                      reader.onload = e => {
                          console.log(e.target.result);
                          setImagen(e.target.result)
                      };
                      reader.readAsDataURL(file);
              
                      // Prevent upload
                      return false;
                    }}
                    
            ><Button type="primary">Click para subir Imagen</Button>
            </Upload> */}
        </Form.Item>
      </Form>
    </Modal>
  );
}
