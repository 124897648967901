import React from 'react'

const navigationAction = (proyecto, escenaIndex, setEscenaIndex) => {
    console.log(
        "%c CLICK EN la ESFERA",
        "color: teal",
        proyecto.escenas[escenaIndex].elementos
      );
      const listaElementosAux = proyecto.escenas[escenaIndex].elementos;

      //limpiemos los elementos de la escena
      let listaElementos = listaElementosAux.reduce((acc, curr) => {
        //logica que solo mira los elementos de enlace
        if (curr.tipo === 2) {
          acc.push(curr);
          
        }
        //este metodo elegia un elemento de cada tipo
        // if (!acc.some(item => item.tipo === curr.tipo)) {
        //   if(curr.tipo !== -1){
        //     acc.push(curr);
        //   }          
        // }
          return acc;
      }, []);

      console.log("%c LISTA DE ELEMENTOS MODIFICADA", "color: orange; font-size: 16px", listaElementos);


      const camera = document.querySelector("#camara");
      let posInicial = camera.getAttribute("position");
      // Función para animar cada elemento secuencialmente
      const animateElements = async () => {
        for (const element of listaElementos) {
          console.log("%c ELEMENTO", "color: blue", element);

          if (element && element.position) {
            // Highlight current element
            const elementEntity = document.getElementById(element.id);
            console.log(
              "%c ELEMENTO ENTITY",
              "color: yellow",
              elementEntity
            );
            if (elementEntity) {
              // Get z position and calculate scale factor
              const zPos = Math.abs(element.position.z);
              const xPos = Math.abs(element.position.x);
              let scaleFactor = 1;
              if (zPos > 50) {
                scaleFactor = 8; 
              } else if (zPos > 20 && xPos > 50) {
                 scaleFactor = 6;
               }else{
                scaleFactor = 2;
              }
               // else if (zPos > 10) {
              //   scaleFactor = 4;
              // } else {
              //   scaleFactor = 2;
              // }

              elementEntity.setAttribute(
                "animation", 
                `property: scale; to: ${scaleFactor} ${scaleFactor} ${scaleFactor}; dur: 2000; easing: easeInOutQuad`
              );

              elementEntity.setAttribute("animation__color", {
                property: "material.color",
                //to: "#FFFF00",
                to: "#EE9402",
                dur: 1000,
              });

              //texto segun tipo de elemento
              let textElement = "Elemento Interactivo!";
              if (element.tipo === 2) {
                textElement =
                  "Click para Navegar a la escena" +
                  "\n" +
                  `${element.escena}`;
              }
              if (element.tipo === 1) {
                textElement =
                  "Click para ver información" + "\n" + "complementaria";
              }
              if (element.tipo === 3) {
                textElement =
                  "Click para ver imagen" + "\n" + "relacionada";
              }
              if (element.tipo === 0) {
                textElement =
                  "Click para ver video" + "\n" + "relacionado";
              }
              // Creamos el elemento texto
              const textEntity = document.createElement("a-text");
              textEntity.setAttribute("id", `text-${element.id}`);
              textEntity.setAttribute("value", textElement);
              textEntity.setAttribute("scale", "6 6 6");
              textEntity.setAttribute("align", "center");
              textEntity.setAttribute("color", "white");
              textEntity.setAttribute("shader", "msdf");
              textEntity.setAttribute("negate", false);
              textEntity.setAttribute(
                "font",
                "/fonts/custom-font-msdf/customFontAframe-msdf.json"
              );
              textEntity.setAttribute(
                "font-image",
                "/fonts/custom-font-msdf/customFontAframe.png"
              );

              // Calculate text width
              const textWidth =
                textEntity.getAttribute("value").length * 0.5; // Approximate width based on text length
              const planeWidth = Math.max(textWidth, 4); // Minimum width of 4 units

              const numberOfLines = textEntity
                .getAttribute("value")
                .split("\n").length;
              const planeHeigth = Math.max(numberOfLines * 2, 4); // Minimum height of 4 units, with 2 units per line

              // Create plane with calculated width
              const planeEntity = document.createElement("a-plane");
              planeEntity.setAttribute("id", `plane-${element.id}`);
              planeEntity.setAttribute("color", "black");
              planeEntity.setAttribute("opacity", "1");
              planeEntity.setAttribute("height", planeHeigth);
              planeEntity.setAttribute("width", planeWidth);
              planeEntity.setAttribute("position", "0 5 0");

              // Add text on plane with adjusted position
              textEntity.setAttribute("position", { x: 0, y: 0, z: 0 });
              planeEntity.appendChild(textEntity);
              elementEntity.appendChild(planeEntity);

              
              // elementEntity.setAttribute('animation__scale', {
              //   property: "scale",
              //   to: "1 1 1",
              //   dur: 1000
              // });
            }                                       

            // Calculate rotation to look at the element
            const targetPos = element.position;

            // Calculate angles needed to look at the target
            const dx = targetPos.x;
            const dy = targetPos.y;
            const dz = targetPos.z;

                              

            // Calculate yaw (y-axis rotation) and normalize to 0-360 range
            let yaw = (Math.atan2(dx, dz) * 180) / Math.PI;
            
            
            // Normalize yaw angle to be between 0 and 360 degrees
            if (dz < 0) {
              yaw = ((yaw % 360) + 360) % 360; // Normalize to 0-360
              if(yaw >0){
                yaw = yaw -180;
              }else{
                yaw = -180 - yaw;
              }
            }else{
              if(yaw < 0){
                yaw = -180 + yaw;
              }else{
                if(dx>0){
                    yaw = -180+yaw 
                }else{
                  yaw = 180 - yaw;
                }
              }
            }
            console.log("%c YAW USADO", "color: orange; font-size: '20px'", yaw);

            // Calculate pitch (x-axis rotation)
            const distance = Math.sqrt(dx * dx + dz * dz);
            let pitch = -((Math.atan2(dy, distance) * 180) / Math.PI);
            console.log("%c PITCH", "color: orange; font-size: '20px'", pitch);
            if(dy < 0){
              pitch = -pitch;
            }
            if(dy>0 && pitch < 0){
              pitch = -pitch;
            }


            // Animate camera rotation to look at element
            camera.setAttribute("animation__rotation", {
              property: "rotation",
              from: posInicial,
              to: `${pitch} ${yaw} 0`,
              dur: 15000,
              easing: "easeInOutQuad",
            });

            
            posInicial = `${pitch} ${yaw} 0`;
            

            if(dz>0){
            elementEntity.setAttribute("animation__rotate", {
              property: "rotation",
              to: "0 180 0", 
              dur: 15000,
              easing: "linear",                    
            });
          }

            // Small delay before moving to next element
            await new Promise((resolve) => setTimeout(resolve, 15000));
          }
        }//fin del for


        for (const element of listaElementos) {
          const elementEntity = document.getElementById(element.id);
          if (elementEntity) {
            const planeToRemove = document.getElementById(`plane-${element.id}`);
            if (planeToRemove) {
              planeToRemove.parentNode.removeChild(planeToRemove);
            };
            // elementEntity.setAttribute("animation__scale", {
            //   property: "scale",
            //   to: "1 1 1",
            //   dur: 1000
            // });
            elementEntity.setAttribute(
              "animation", 
              `property: scale; to: 1 1 1; dur: 2000; easing: easeInOutQuad`
            );

            elementEntity.setAttribute("animation__color", {
              property: "material.color",
              to: "#E94E1B",
              dur: 1000,
            });
            
          }
        }        

        // vamos a establecer el elemento para jump
        let indiceJump = 0;
        let escenaElement = null;
        const ConfigElement = proyecto.escenas[escenaIndex].elementos.find(element => element.tipo === -1);
        console.log("CONFIG ELEMENT ENCONTRADO", ConfigElement);
        if(ConfigElement){
         // escenaElement = proyecto.escenas[escenaIndex].elementos[indiceJump];
         escenaElement = proyecto.escenas[escenaIndex].elementos.find(element => element.id === ConfigElement.jumpNextSceneId);
        }else{
          escenaElement = proyecto.escenas[escenaIndex].elementos.find(element => element.tipo === 2);

        }

        console.log("%c ELEMENTO PARA JUMP", "color: green", escenaElement);

        // Si encontramos un elemento de escena, animamos la cámara hacia él
        if (escenaElement) {
          //capturo la camara actual
          const camaraActual = document.querySelector("#camara");

          //voy a crear un a-plane para este mensaje
          const planeMessage = document.createElement("a-plane");
          planeMessage.setAttribute("id", `plane-${escenaElement.id}`);
          planeMessage.setAttribute("color", "black");
          planeMessage.setAttribute("opacity", "0.9");
          planeMessage.setAttribute("height", "4");
          planeMessage.setAttribute("width", "10");
          planeMessage.setAttribute("position", "0 0 -1");

          
          // textEntity.setAttribute("position", { x: 0, y: 0, z: 0 });
          // planeEntity.appendChild(textEntity);
          // elementEntity.appendChild(planeEntity);

          const messageFloat = document.createElement("a-entity");
          messageFloat.setAttribute("position", "0 0 0");
          messageFloat.setAttribute("text", {
            value: 
              escenaElement.escena !== undefined 
                ? "Navegando hacia la escena: " + escenaElement.escena 
                : "No hay escena destino definida",
              
            align: "center",
            width: 2,
            color: "white",
            shader: "msdf",
            negate: false,
            font: "/fonts/custom-font-msdf/customFontAframe-msdf.json",
            "font-image": "/fonts/custom-font-msdf/customFontAframe.png",
          });

          planeMessage.appendChild(messageFloat);
          camaraActual.appendChild(planeMessage);

          // Remove after 5 seconds
          setTimeout(() => {
            camaraActual.removeChild(planeMessage);
            
          }, 5000);
          try {
            const indiceEscena = proyecto.escenas.findIndex( (escena) => escena.titulo === escenaElement.escena);
            console.log("%c INDICE ESCENA", "color: green", indiceEscena);
            //let controls = camara.current.components["look-controls"];
            if(indiceEscena > -1){
            setEscenaIndex(indiceEscena);                    
            }
          } catch (error) {
            console.error("Error al cambiar de escena:", error);                    
          }
        }
      };

      if(proyecto.escenas[escenaIndex].elementos.length > 0){
        animateElements();
      }
      
    
}

export default navigationAction
