import React, { useEffect, useState } from 'react';
import { Flex, Typography } from 'antd';
import visitasSvg from '../assets/eye.svg';

// import soundSvg from '../assets/sound.svg';
// import noSoundSvg from '../assets/no_sound.svg';

import './controles.css';
import "animate.css";


const Visitas = ({cantidad}) => {
  // estado que dispara el sonido ambiente
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);
  const [volume, setVolume] = useState(1)

  const handleSound = (orden) => {
    console.log("PASANDO POR HANDLE SOUND")
     // play de sonido
     const soundEntity = document.getElementById("sound-entity");
     //var soundEntity = document.querySelector('[sound]');
     if (!soundEntity) {
       console.error("Sound entity not found");
       return;
     }
     
     try {
       if(orden){
         if (soundEntity.components && soundEntity.components.sound) {
          soundEntity.components.sound.playSound();  
         }
       } else {
        if (soundEntity.components && soundEntity.components.sound) {
          soundEntity.components.sound.pauseSound();
        }
       }
     } catch (error) {
       console.error("Error handling sound:", error);
     }
  }

  

  return (
  <div
    
    id='visitasComponent'    
  >
    <Flex
        horizontal
        align="center"        
        // justify="space-between"
        style={{
          padding: 0,
          margin: 0,          
          width: '100%',
          justifyContent: 'space-between',
          marginRight: 20,
        }}
        
      >
        {/* <img
        alt="sound"
        src={isSoundPlaying? soundSvg : noSoundSvg} 
        style={{width: 40, height: 40, marginRight: 20, padding: 0, filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 1))'}}        
        className="itemMenuFloat"
        onClick={() => {         
         setIsSoundPlaying(prevState => {
          const newState = !prevState;
          handleSound(newState);
          return newState;
        });
      
        }}
      />  
      {isSoundPlaying && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume || 1}
            onChange={(e) => {
              const newVolume = parseFloat(e.target.value);
              setVolume(newVolume);
              
              const soundEntity = document.getElementById("sound-entity");
              if (soundEntity && soundEntity.components && soundEntity.components.sound) {
                soundEntity.setAttribute('sound', 'volume', newVolume);
              }
            }}
            style={{
              width: '80px',
              margin: '0 10px',
              accentColor: '#FFFFFF',
              filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 1))'
            }}
          />
        </div>
      )}
         */}
      <img
        alt="avatar"
        src={visitasSvg} 
        style={{width: 40, height: 40, margin: 0, padding: 0, filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 1))'}}        
      />
      
        <Typography.Title level={5} style={{fontFamily: 'Spartan', color: 'rgba(255, 255, 255, .4)', margin: 0, padding: 0,filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 1))'}} id='visitas'>
          {cantidad}
        </Typography.Title>        
      
    </Flex>
  </div>
)};


export default Visitas;